<template>
    <div class="work-detail page-wrapper" v-if="dataLoaded">
        <div
            class="intro-section position-relative text-uppercase container-longer mobile-fullscreen"
        >
            <transition name="fade">
                <div
                    :class="
                        currProject[0].acf.projects_video.video_id
                            ? ''
                            : 'no-video'
                    "
                    class="project-title d-none d-lg-block text-center"
                    v-if="videoPaused"
                ></div>
            </transition>
            <!-- intro video -->
            <video-comp
                video_tag_id="videoProjectDetailIntro"
                :video_id="currProject[0].acf.projects_video.video_id"
                :video_img="currProject[0].acf.projects_video.video_image.url"
                :btn_color="currProject[0].acf.accent_color"
                @playing="playing"
            ></video-comp>
        </div>

        <ArticleHeader
            :title="currProject[0].acf.project_name"
            :subtitle="currProject[0].acf.client_name"
            :color="currProject[0].acf.accent_color"
        />

        <!-- intro sekcija, naslov, credits... -->
        <projectDetailInfo
            :accent_color="currProject[0].acf.accent_color"
            :about_text="currProject[0].acf.about_the_project__text"
            :credits_list="currProject[0].acf.credits_list"
            :our_role="currProject[0].categories"
        ></projectDetailInfo>

        <!-- modularne sekcije -->
        <template
            v-if="
                currProject[0].acf.modular_sections &&
                    currProject[0].acf.modular_sections.length > 0
            "
        >
            <Modules
                :modules="currProject[0].acf.modular_sections"
                :color="currProject[0].acf.accent_color"
            />
        </template>
        <!-- kraj modularnih sekcija -->

        <div class="bg-grayish" v-in-viewport.once>
            <div class="container-longer container-fluid fade-item">
                <div class="row py-4">
                    <div
                        class="col-12 col-md-6 text-center text-md-left py-1 py-md-0"
                    >
                        <router-link
                            to="/"
                            @mouseenter.native="darken"
                            @mouseout.native="reset"
                            class="title-3 text-uppercase no-underline transition-opacity"
                            :style="{ color: currProject[0].acf.accent_color }"
                            >back to homepage</router-link
                        >
                    </div>
                    <div
                        class="col-12 col-md-6 text-center text-md-right py-1 py-md-0"
                    >
                        <a
                            @click.prevent="$router.go(-1)"
                            @mouseenter="darken"
                            @mouseout="reset"
                            class="title-3 text-uppercase no-underline transition-opacity cursor-pointer"
                            :style="{ color: currProject[0].acf.accent_color }"
                        >
                            back to Portfolio
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import projectDetailInfo from "@/components/ProjectDetailInfo.vue";
import Video from "@/components/Video.vue";
import ArticleHeader from "../components/ArticleHeader.vue";
import Modules from "../components/Modules.vue";
import seoMixin from "@/mixins/seoMixin";
import Footer from "../components/Footer.vue";

export default {
    name: "work-detail",
    mixins: [seoMixin],
    data() {
        return {
            // trenutni projekt koji se gleda
            currProject: [],
            videoPaused: true,
            dataLoaded: false
        };
    },
    computed: {
        ...mapGetters(["projects"])
    },
    components: {
        ArticleHeader,
        videoComp: Video,
        Modules,
        projectDetailInfo,
        Footer
    },
    created() {
        // tražimo u Vuex-u u svim projektima trenutni projekt sa prosljeđenim slug-om
        this.currProject = this.projects.filter(project => {
            return project.slug === this.$route.params.slug;
        });

        // we must check if project exists, if not, redirect to 404
        if (this.currProject.length === 0) this.$router.push("/404");
        else {
            this.dataLoaded = true;
            this.setSeo(
                this.currProject[0].acf.seo_title,
                this.currProject[0].acf.seo_description,
                this.currProject[0].acf.projects_video.video_image.url,
                "https://www.wearealive.com" + this.$route.fullPath,
                this.currProject[0].acf.is_hidden
            );
            setTimeout(() => {
                $("body").css("visibility", "unset");
                this.$store.dispatch("setLoading", false);
            }, loadingDelay);
            this.$nextTick(() => {
                $(".gallery-slider").each(function(index, slider) {
                    $(slider)
                        .find(".carousel-indicators li")
                        .css({
                            "border-color": $(slider).attr("data-btn-color"),
                            "background-color": "transparent"
                        });
                    $(slider)
                        .find(".carousel-indicators li.active")
                        .css(
                            "background-color",
                            $(slider).attr("data-btn-color")
                        );
                });
            });

            document.dispatchEvent(new Event("custom-render-trigger"));
        }
    },
    methods: {
        playing() {
            this.videoPaused = false;
        },
        darken(evt) {
            evt.currentTarget.style.color = this.currProject[0].acf.accent_color;
            evt.currentTarget.style.opacity = ".7";
        },
        reset(evt) {
            evt.currentTarget.style.color = this.currProject[0].acf.accent_color;
            evt.currentTarget.style.opacity = "1";
        }
    }
};
</script>
