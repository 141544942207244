<template>
    <div class="project-detail-info bg-primary text-white accordion">
        <div
            class="container-longer container-fluid py-5 py-xl-7"
            v-in-viewport.once
        >
            <div class="row fade-item">
                <div class="col-12 col-xl-6 pr-xl-4 pr-xxl-6">
                    <h3
                        class="title-3 text-uppercase cursor-pointer d-flex align-items-center"
                        :style="{ color: accent_color }"
                        v-b-toggle.accordion-0
                    >
                        about the project
                        <small
                            class="icon icon-arrow-down d-flex align-items-center ml-3 d-xl-none"
                        ></small>
                    </h3>
                    <mq-layout :mq="['xs', 'sm', 'md', 'lg']">
                        <b-collapse
                            visible
                            id="accordion-0"
                            accordion="accordion-0"
                            ref="accordion-0"
                            role="tabpanel"
                        >
                            <div
                                v-html="about_text"
                                class="text leading-normal"
                            ></div>
                        </b-collapse>
                    </mq-layout>
                    <mq-layout mq="xl+">
                        <div v-html="about_text" class="mt-4 text"></div>
                    </mq-layout>
                </div>
                <div class="col-12 col-xl-3 pr-xl-4">
                    <h3
                        class="title-3 text-uppercase cursor-pointer d-flex align-items-center"
                        :style="{ color: accent_color }"
                        v-b-toggle.accordion-1
                    >
                        credits
                        <small
                            class="icon icon-arrow-down d-flex align-items-center ml-3 d-xl-none"
                        ></small>
                    </h3>
                    <mq-layout :mq="['xs', 'sm', 'md', 'lg']">
                        <b-collapse
                            id="accordion-1"
                            accordion="accordion-1"
                            role="tabpanel"
                            ref="accordion-1"
                        >
                            <ul class="list-unstyle">
                                <li
                                    v-for="(credit, index) in credits_list"
                                    :key="index"
                                >
                                    <strong class="font-weight-bold"
                                        >{{ credit.label }}:&nbsp</strong
                                    >
                                    <span class="font-weight-light">{{
                                        credit.credit
                                    }}</span>
                                </li>
                            </ul>
                        </b-collapse>
                    </mq-layout>
                    <mq-layout mq="xl+">
                        <ul class="list-unstyle mt-4">
                            <li
                                v-for="(credit, index) in credits_list"
                                :key="index"
                            >
                                <strong class="font-weight-bold"
                                    >{{ credit.label }}:&nbsp</strong
                                >
                                <span class="font-weight-light">{{
                                    credit.credit
                                }}</span>
                            </li>
                        </ul>
                    </mq-layout>
                </div>
                <div class="col-12 col-xl-3 pl-xl-4 list-section">
                    <h3
                        class="title-3 text-uppercase cursor-pointer d-flex align-items-center"
                        :style="{ color: accent_color }"
                        v-b-toggle.accordion-2
                    >
                        our role
                        <small
                            class="icon icon-arrow-down d-flex align-items-center ml-3 d-xl-none"
                        ></small>
                    </h3>
                    <mq-layout :mq="['xs', 'sm', 'md', 'lg']">
                        <b-collapse
                            id="accordion-2"
                            accordion="accordion-2"
                            role="tabpanel"
                            ref="accordion-2"
                        >
                            <p>
                                <router-link
                                    v-for="(categoryId, id) in our_role"
                                    :to="{
                                        name: 'work',
                                        query: {
                                            filter: $store.getters.categoriesById(
                                                categoryId
                                            )[0].slug
                                        }
                                    }"
                                    class="list-text-comma text-white"
                                    :key="categoryId"
                                >
                                    <template
                                        v-if="id === our_role.length - 1"
                                        >{{
                                            $store.getters.categoriesById(
                                                categoryId
                                            )[0].name
                                        }}</template
                                    >
                                    <template v-else
                                        >{{
                                            $store.getters.categoriesById(
                                                categoryId
                                            )[0].name
                                        }}&#44;</template
                                    >
                                </router-link>
                            </p>
                        </b-collapse>
                    </mq-layout>
                    <mq-layout mq="xl+">
                        <!-- slanje query stringa sa slugom kategorije koja je kliknuta, obrađuje se na Work stranici pod activated -->
                        <p class="mt-4">
                            <router-link
                                v-for="(categoryId, id) in our_role"
                                :to="{
                                    name: 'work',
                                    query: {
                                        filter: $store.getters.categoriesById(
                                            categoryId
                                        )[0].slug
                                    }
                                }"
                                class="list-text-comma text-white d-inline-block pr-1"
                                :key="categoryId"
                            >
                                <template v-if="id === our_role.length - 1">{{
                                    $store.getters.categoriesById(categoryId)[0]
                                        .name
                                }}</template>
                                <template v-else
                                    >{{
                                        $store.getters.categoriesById(
                                            categoryId
                                        )[0].name
                                    }}&#44;</template
                                >
                            </router-link>
                        </p>
                    </mq-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: ["accent_color", "about_text", "credits_list", "our_role"]
};
</script>
